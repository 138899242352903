@import "./variables.scss";

.sixai {
    &-loader {
        color: #fff;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;

        &-fixed {
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0;
            right: 0;
            z-index: 999999999999999;
            background-color: rgb(33 37 41 / 60%);
            color: #fff;
        }

        &-dark {
            background-color: #000;
            backdrop-filter: blur(3px);
        }
    }

    &-btn {
        @include vendor-prefix(border-radius, 8px !important);
        font-weight: 600 !important;
        box-shadow: none !important;
        padding: 8px 20px !important;
        white-space: nowrap;

        &-primary {
            border-color: #e3c050 !important;
            color: #fff !important;
            background-color: #000 !important;

            &:hover,
            &.active {
                color: #e3c050 !important;

                svg {
                    path {
                        &[stroke] {
                            stroke: #e3c050;
                        }

                        &[fill] {
                            fill: #e3c050;
                        }
                    }
                }
            }
        }

        &-secondary {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: #373737 !important;
            color: #8c8e95 !important;

            &:hover,
            &.active {
                background-color: #454648 !important;
                border-color: #454648 !important;
                color: #fff !important;
            }
        }

        &-light {
            @include vendor-prefix(border-radius, 8px !important);
            border-color: #454648 !important;
            color: #8c8e95 !important;

            &:hover,
            &.active {
                background-color: #454648 !important;
                border-color: #454648 !important;
                color: #fff !important;
            }
        }

        &-dark {
            @include vendor-prefix(border-radius, 50px !important);
            border-color: #4e4e4e !important;
            color: #8c8e95 !important;
            background-color: #4e4e4e !important;

            &:hover {
                border-color: #4e4e4e !important;
                background-color: #4e4e4e !important;
                color: #fff !important;
            }
        }



        &-md {
            padding: 11px 30px !important;
        }

        &-sm {
            padding: 5px 15px !important;
            text-transform: none !important;
            font-weight: normal !important;
        }

        &-round {
            @include vendor-prefix(border-radius, 50px !important);
        }
    }
}



.sixAIPopup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    background: rgba(19, 19, 19, 0.7);
    backdrop-filter: blur(10px);
    overflow: auto;
    padding-top: 40px;
    padding-bottom: 40px;

    &-close {
        position: absolute;
        cursor: pointer;
        right: 0px;
        top: 0;
        padding: 10px 15px;

        &:hover {
            svg {
                path {
                    &[stroke] {
                        stroke: #fff;
                    }

                    &[fill] {
                        fill: #fff;
                    }
                }
            }
        }
    }

    &-inner {
        position: relative;
        color: #fff;
        gap: 10px;
        width: 1000px;
        background: #1F1F1F;
        border: 1px solid #373737 !important;
        border-radius: 6px;
        padding: 24px 24px;
        max-width: 95%;
        margin: auto auto;
    }

    &-sm & {
        &-inner {
            width: 500px;
        }
    }

    &-xs & {
        &-inner {
            width: 400px;
        }
    }

    &-md & {
        &-inner {
            width: 700px;
        }
    }

    &-header {
        padding-bottom: 20px;

        &-heading {
            color: #e9e9e9 !important;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
        }

        &-heading2 {
            color: #e9e9e9 !important;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
        }

        &-desc {
            color: #8c8e95;
            font-size: 14px;
            margin-top: 10px;
            text-align: center;
        }
    }

    &-content {
        width: 100%;
    }

    &-footer {
        text-align: center;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #2B2B2B !important;
    }
}

.custom-form {
    margin: 0;

    &-group {
        width: 100%;
        margin-bottom: 14px !important;
    }

    &-label {
        font-size: 13px !important;
        color: #8c8e95 !important;
        margin-bottom: 4px;
        display: inline-block;

        &.big-label {
            font-size: 14px !important;
            margin-bottom: 3px !important;
        }
    }

    &-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 20px;
        margin-top: 40px;

        svg {
            width: 14px;
            height: 14px;

            path {
                fill: #10b981;
            }
        }
    }

    &-input {
        background-color: #131313 !important;
        color: #fff !important;
        border: 1px solid #373737 !important;
        @include vendor-prefix(border-radius, 8px !important);
        outline: none !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        height: 46px;
        resize: none;
        padding: 0.5rem 0.75rem;

        &[type="password"] {
            &:not(:placeholder-shown) {
                letter-spacing: 0.5px;
                font-size: 200% !important;
                line-height: 20px;
            }
        }

        @include vendor-placeholder {
            color: #8c8e95 !important;
        }

        &[disabled] {
            background-color: #2B2B2B !important;

            @include vendor-placeholder {
                color: #575757;
            }
        }

        &-inline {
            position: relative;
            align-items: center;

            &-input {
                padding-right: 120px;
            }
        }

        &-surfix {
            position: absolute;
            right: 4px;
            display: flex;
            text-align: center;
            justify-content: center;
            cursor: pointer;
            gap: 7px;
            font-size: 14px;
            color: #8c8e95;
            padding: 7px 7px;
            @include vendor-prefix(border-radius, 6px);
            background-color: #2B2B2B;

            &-top {
                top: 4px;
            }

            &.disabled {
                cursor: auto;
            }

            &:not(.disabled) {

                &:hover,
                &:focus {
                    color: #e3c050;
                }
            }
        }

        &-sm {
            font-size: 14px !important;
        }

        &:focus,
        &.active {
            border-color: #e3c050 !important;
            box-shadow: none;
        }

        &-row {
            position: relative;
        }

        &-additional {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0px;
            padding: 0px 5px;
            display: flex;
            align-items: center;
            gap: 5px;

            button {
                margin: auto;
            }
        }
    }

    &-date {
        >div {
            width: 100%;
            background-color: #131313 !important;
            @include vendor-prefix(border-radius, 8px !important);

            button {
                svg {
                    fill: #8c8e95;
                }
            }
        }

        fieldset {
            color: #fff !important;
            border: 1px solid #373737 !important;
            @include vendor-prefix(border-radius, 8px !important);
            outline: none !important;
            font-size: 16px !important;
            font-weight: 400 !important;
        }

        input {
            background-color: #131313 !important;
            color: #fff !important;
            outline: none !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            padding-top: 11px;
            padding-bottom: 11px;
        }

        button.MuiIconButton-root {
            position: unset;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    &-file {
        display: flex;
        cursor: pointer;
        color: #8c8e95 !important;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        gap: 10px;

        &-input {
            display: none;
        }

        &-preview {
            width: 40px;
            height: 40px;
            border: 1px solid #373737;
            background: #131313;
            @include vendor-prefix(border-radius, 8px);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            &-round {
                @include vendor-prefix(border-radius, 50%);
            }

            img {
                object-fit: cover;
                max-height: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        svg {}
    }

    &-input {
        width: 100%;
    }

    &-checkbox {
        position: relative;

        &-label {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: #8c8e95 !important;
            margin-bottom: 7px;
            display: inline-block;
            padding-left: 30px;
            cursor: pointer;
            position: relative;
            line-height: 20px;

            &::before {
                content: "";
                width: 20px;
                height: 20px;
                background-color: #131313 !important;
                border: 1px solid #373737 !important;
                position: absolute;
                left: 0px;
                @include vendor-prefix(border-radius, 4px);
                text-align: center;
                line-height: 20px;
                overflow: hidden;
            }
        }

        &-normal & {
            &-label {
                padding-left: 0;
                padding-right: 30px;

                &::before {
                    right: 0;
                    left: auto;
                }
            }
        }

        &-input {
            height: 0;
            width: 0;
            visibility: hidden;
            opacity: 0;
            display: none;
        }

        &-input:checked+&-label {
            &::before {
                content: "\2713";
                border-color: #e3c050 !important;
                color: #e3c050 !important;
            }
        }
    }

    &-tags {
        .react-tagsinput {
            width: 100%;
            border: none;
            padding: 8px !important;
            @include vendor-prefix(border-radius, 8px);
            min-height: 116px;
            max-height: 150px;
            overflow: auto;
            background-color: #131313;
            color: #fff;
            border: 1px solid #373737;

            &-tag {
                background: #373737;
                border: none;
                margin: 0;
                padding: 6px 8px;
                margin: 5px;
                @include vendor-prefix(border-radius, 6px);
                font-size: 14px;
                color: #fff;
            }

            &-input {
                width: 200px;
                margin: 0;
                background-color: transparent;
            }
        }
    }

    &-pin {
        gap: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-input {
            text-align: center;
            height: 60px;
            font-weight: 600 !important;
        }
    }
}

.mt {
    &-auto {
        margin-top: auto !important;
    }

    &-0 {
        margin-top: 0px !important;
    }

    &-5 {
        margin-top: 5px !important;
    }

    &-10 {
        margin-top: 10px !important;
    }

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }
}

.mb {
    &-auto {
        margin-bottom: auto !important;
    }

    &-0 {
        margin-bottom: 0px !important;
    }

    &-5 {
        margin-bottom: 5px !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-15 {
        margin-bottom: 15px !important;
    }

    &-20 {
        margin-bottom: 20px !important;
    }
}

.ml {
    &-auto {
        margin-left: auto !important;
    }

    &-0 {
        margin-left: 0px !important;
    }

    &-5 {
        margin-left: 5px !important;
    }

    &-10 {
        margin-left: 10px !important;
    }

    &-15 {
        margin-left: 15px !important;
    }

    &-20 {
        margin-left: 20px !important;
    }
}

.mr {
    &-auto {
        margin-right: auto !important;
    }

    &-0 {
        margin-right: 0px !important;
    }

    &-5 {
        margin-right: 5px !important;
    }

    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }

    &-20 {
        margin-right: 20px !important;
    }
}


.text {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-underline {
        text-decoration: underline;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-primary {
        color: #e3c050 !important;
    }

    &-light {
        color: #C0C0C0;
    }

    &-white {
        color: #ffffff;
    }

    &-link {
        cursor: pointer;
        color: inherit;
        text-decoration: none;

        &:hover {
            color: #e3c050 !important;
        }
    }
}