@import "../../../assets/css/variables.scss";


.userLogin {
    font-size: 14px;

    &-social {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;

        iframe {
            width: 100% !important;
        }

        &-btn {
            padding: 10px 20px;
            text-align: center;
            border: 1px solid #373737;
            @include vendor-prefix(border-radius, 8px);
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            height: 48px;
            color: #C0C0C0;
            text-decoration: none;

            svg {
                margin-right: 10px;
            }

            &:active,
            &:hover {
                background-color: #373737;
                color: #fff;
            }

            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    &-or {
        position: relative;
        text-align: center;
        color: #575757;
        font-size: 14px;
        margin: 25px 0px;

        &-text {
            &-inner {
                font-weight: 700;
                background: #1F1F1F;
                position: relative;
                padding-left: 10px;
                padding-right: 10px;
                z-index: 1;
                font-size: 14px;
            }

            &::before,
            &::after {
                content: "";
                height: 1px;
                background-color: #373737;
                position: absolute;
                top: 50%;
                width: 45%;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }
    }

    &-line {
        position: relative;
        margin: 20px 0px;
        height: 1px;
        background-color: #373737;
    }

    &-email {
        width: 100%;

        &-text {
            font-size: 13px;
            color: rgba(255, 255, 255, 0.60);
        }

        &-support {
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;

            &-block {
                display: flex;
                align-items: center;
                gap: 4px;

                svg {
                    width: 30px;
                    height: 30px;
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        &-btn {
            padding: 10px 20px;
            text-align: center;
            border: 1px solid #e3c050;
            @include vendor-prefix(border-radius, 8px);
            background-color: #000;
            color: #fff;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 700;
            height: 48px;
            text-transform: uppercase;

            &:hover {
                color: #e3c050;
            }
        }

        &-msg {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
        }

        &-link {
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            color: #C0C0C0;
            padding: 5px 0px;
            text-decoration: none;

            &:hover {
                color: #e3c050;
            }

            &:hover {
                svg {
                    path {
                        &[stroke] {
                            stroke: #e3c050;
                        }

                        &[fill] {
                            fill: #e3c050;
                        }
                    }
                }
            }
        }

        &-policy {
            text-align: center;
            font-size: 13px;
            color: #575757;
        }

        &-footer {
            display: flex;
            margin-top: 50px;
            justify-content: center;

            svg {
                display: inline-block;
            }

            &-col {
                display: flex;
                align-items: center;
                width: auto;

                &:last-child:not(:first-child) {
                    margin: 0px 0px 0px auto;
                }
            }
        }
    }
}