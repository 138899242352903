.sixai-page-privacy {
    padding: 24px 16px;
    overflow-y: auto;
    flex-direction: column;

    &-inner {
        max-width: 1130px;
        margin: 24px auto 50px !important;
        width: 100%;
    }

    &-heading {
        font-weight: bold;
        color: #e9e9e9;
    }

    &-text {
        color: #c0c0c0;
        font-size: 16px;
        margin-top: 24px;
    }

}